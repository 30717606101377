import PortalPrograms from '@classes/Programs'
import { IJoinOnlineInput } from '@components/JoinLessonButton/interface'
import { GET_ONLINE_CLASSROOM } from '@queries/onlineClassrooms'
import { GET_STUDENT } from '@queries/student'
import React from 'react'
import { Query } from 'react-apollo'

interface QueryPageResults {
  loading: boolean
  data: any
  error: any
}

interface IGetOnlineClassroomURL {
  roomDetails: IJoinOnlineInput
  children: (results: QueryPageResults) => {}
  studentId?: string
  onCompleted?: (data?: any) => void
}

const GetOnlineClassroomURL: React.FC<IGetOnlineClassroomURL> = ({
  roomDetails,
  children,
  studentId,
  onCompleted = () => {},
}) => {
  const mainQuery = (params) => (
    <Query query={GET_ONLINE_CLASSROOM} variables={params} fetchPolicy="network-only" onCompleted={onCompleted}>
      {(response) => children(response)}
    </Query>
  )

  if (!studentId) {
    return mainQuery(roomDetails)
  }

  return (
    <Query query={GET_STUDENT} variables={{ StudentId: studentId }} fetchPolicy="network-only">
      {(studentRes) => {
        if (studentRes.loading) {
          return null
        }

        const materialId = new PortalPrograms(
          studentRes?.data?.getStudentById?.StudentProfileInfo?.ProgramStudentWrappers
        ).getProgram(roomDetails.roomId)?.program?.Materials?.[0]?.LPID
        __DEV__ && console.log('materialId: %o', materialId)

        return mainQuery({ ...roomDetails, custom_tp_playlist_id: materialId })
      }}
    </Query>
  )
}

export default GetOnlineClassroomURL
