import gql from 'graphql-tag'

export const GET_STUDENT = gql`
  query GetStudent($StudentId: String!) {
    getStudentById(StudentId: $StudentId) {
      StudentProfileInfo {
        ProgramStudentWrappers {
          ProgramStudentInfo {
            ProgramId
            Materials {
              NewRowPlaylistId
              LPID
            }
          }
        }
      }
    }
  }
`
