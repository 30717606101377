import ImagePopupBlocker from '@berlitzplatforms/micro.image.popup-blocker'
import ModalTransactional from '@berlitzplatforms/micro.modal.transactional'
import Modal from '@berlitz/modal'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { PopupBlockerContentProps, PopupBlockerModalProps } from './interface'
import { LinkWrapper, PopupBlockerContentContainer } from './style'

export const PopupBlockerContent: FC<PopupBlockerContentProps> = ({ onProceed = () => {} }) => {
  const router = useRouter()
  const paragraph1 =
    'To access your lesson please change the settings on your browser to allow pop-ups and reload the page'
  const paragraph2 = 'To view instruction for your browser please'
  const linkText = 'click here'
  const url = '/support/?qid=blt684742e712b40c9b'
  const handleRedirect = () => {
    router.push(url)
    onProceed()
  }
  return (
    <PopupBlockerContentContainer>
      <ModalTransactional
        image={<ImagePopupBlocker width="300" />}
        title={<FormattedMessage id="Pop-ups blocked" defaultMessage="Pop-ups blocked" />}
        description={
          <>
            <p>
              <FormattedMessage id={paragraph1} defaultMessage={paragraph1} />
            </p>
            <p>
              <FormattedMessage id={paragraph2} defaultMessage={paragraph2} />
              <LinkWrapper>
                <a onClick={handleRedirect}>
                  <FormattedMessage id={linkText} defaultMessage={linkText} />
                </a>
              </LinkWrapper>
              .
            </p>
          </>
        }
        onProceed={() => onProceed()}
        proceedBtnLabel={<FormattedMessage id="Ok" defaultMessage="Ok" />}
      />
    </PopupBlockerContentContainer>
  )
}

class PopupBlockerModal extends React.Component<PopupBlockerModalProps> {
  static Content = PopupBlockerContent

  render() {
    const { open, onClose } = this.props
    return (
      <>
        <Modal open={open} onClose={onClose}>
          <PopupBlockerContent onProceed={onClose} />
        </Modal>
      </>
    )
  }
}

export default PopupBlockerModal
