import gql from 'graphql-tag'

export const PUT_VIRTUAL_CLASSROOM = gql`
  mutation PutVirtualClassroom($virtualClassRoomInput: VirtualClassRoomInput!) {
    putVirtualClassroom(virtualClassRoomInput: $virtualClassRoomInput) {
      StudentId
      SessionTimestamp
      Id
    }
  }
`

export const PUT_SESSION_TIMESTAMP = gql`
  mutation PutSessionTimestamp($sessionTimestampInput: SessionTimestampInput!) {
    putSessionTimestamp(sessionTimestampInput: $sessionTimestampInput) {
      ServiceStatus {
        Success
        StatusCode
        Timestamp
        Message
        Payload
      }
      ServiceResult {
        StudentId
        SessionTimestamp
        Id
      }
      __typename
    }
  }
`
