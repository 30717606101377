import gql from 'graphql-tag'

export const GET_ONLINE_CLASSROOM = gql`
  query getOnlineClassroom(
    $sessionId: String
    $locale: String
    $roomTitle: String!
    $roomId: String!
    $familyName: String!
    $givenName: String!
    $email: String!
    $role: String!
    $userId: String!
    $provider: String!
    $materialId: String
    $custom_tp_playlist_id: String
    $startDate: String
    $endDate: String
  ) {
    getOnlineClassroom(
      provider: $provider
      sessionId: $sessionId
      locale: $locale
      roomTitle: $roomTitle
      roomId: $roomId
      familyName: $familyName
      givenName: $givenName
      email: $email
      role: $role
      userId: $userId
      materialId: $materialId
      custom_tp_playlist_id: $custom_tp_playlist_id
      startDate: $startDate
      endDate: $endDate
    ) {
      provider
      roomUrl
    }
  }
`
