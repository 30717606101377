import Button from '@berlitz/button'
import { _attention } from '@utils/mixins'
import styled, { css } from 'styled-components'

export const JoinButton = styled(Button)<{ small?: boolean }>`
  background-color: ${({ theme }) => theme.palette.green100};
  color: ${({ theme }) => theme.colors.text00};

  &.hidden {
    visibility: hidden;
  }

  ${({ theme }) =>
    _attention(css`
      background-color: ${theme.palette.green100};
      transition: 0.4s;
      opacity: 0.8;
    `)};

  ${({ theme, small }) =>
    small
      ? css`
          padding: ${theme.space.xxs} ${theme.space.xs};
          font-size: ${theme.fontSize.xxxs};
          font-weight: ${theme.fontWeight.bold};
          border: none;
          line-height: normal;
          vertical-align: middle;
        `
      : css`
          font-size: ${theme.fontSize.xxs};
          font-weight: ${theme.fontWeight.bold};
          padding-top: ${theme.space.xs};
          padding-bottom: ${theme.space.xs};
        `};
`

export const JoinAnchor = styled.a`
  color: ${({ theme }) => theme.colors.brandPrimary};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.space.sm};
  margin-right: ${({ theme }) => theme.space.xs};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
