import styled from 'styled-components'
import { H1 as BerlitzH1, P as BerlitzP } from '@berlitz/globals'
import BerlitzButton from '@berlitzplatforms/micro.ui.button'

export const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.danger100};
  height: 106px;
  width: 106px;
  border-radius: 55px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 90px;
    width: 90px;
  }
  svg {
    color: ${({ theme }) => theme.palette.white};
  }
`

export const GenericErrorModalContent = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      font-size: 24px;
      @media (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.fontSize.lg};
      }
    }
    p {
      text-align: center;
      font-size: ${theme.fontSize.sm};
    }
  `}
`

export const Spacing = styled.div`
  ${({ theme }) => `
    &.xl {
      height: ${theme.space.xl};
      @media (max-width: ${theme.breakpoints.md}) {
        height: ${theme.space.lg};
      }
    }

    &.lg {
      height: ${theme.space.lg};
      @media (max-width: ${theme.breakpoints.md}) {
        height: ${theme.space.sm};
      }
    }

    &.md {
      height: ${theme.space.md};
      @media (max-width: ${theme.breakpoints.md}) {
        height: ${theme.space.xs};
      }
    }

    &.sm {
      height: ${theme.space.sm};
      @media (max-width: ${theme.breakpoints.md}) {
        height: ${theme.space.xxs};
      }
    }

    &.xs {
      height: ${theme.space.xs};
      @media (max-width: ${theme.breakpoints.md}) {
        height: ${theme.space.xxs};
      }
    }

    &.xxs {
      height: ${theme.space.xxs};
      @media (max-width: ${theme.breakpoints.md}) {
        height: 0px;
      }
    }

    &.xxxs {
      height: ${theme.space.xxxs};
      @media (max-width: ${theme.breakpoints.md}) {
        height: 0px;
      }
    }
  `}
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const H1 = styled(BerlitzH1)`
  ${({ theme }) => `
    max-width: 578px;
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.bold};
    line-height: 1.19;
    letter-spacing: 1.12px;
    text-align: center;
    color: ${theme.palette.charcoal};
    margin-bottom: 0px;
  `}
`

export const P = styled(BerlitzP)`
  ${({ theme }) => `
    max-width: 546px;
    margin: 5px 0px;
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSize.sm};
    line-height: 1.22;
    letter-spacing: 0.63px;
    text-align: center;
    color: ${theme.palette.charcoal};
  `}
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    ${({ theme }) => `
      padding: ${theme.space.xs} ${theme.space.md};
      margin-left: ${theme.space.xxs};
      margin-right: ${theme.space.xxs};
    `}
  }
`

export const Button = styled(BerlitzButton)`
  ${({ theme }) => `
    padding: ${theme.space.xs} ${theme.space.md};
    margin-left: ${theme.space.xxs};
    margin-right: ${theme.space.xxs};
  `}
`

export const ButtonSpacing = styled.div`
  ${({ theme }) => `
    height: ${theme.space.xxl};
    @media (max-width: ${theme.breakpoints.md}) {
      height: 56px;
    }
  `}
`
