import PortalPrograms from '@classes/Programs'
import GetOnlineClassroomURL from '@components/JoinLessonButton/GetOnlineClassroomURL'
import { FetchingRoomModal, RoomLockedModal, withModal, WithModalProps } from '@components/Modal'
import WithLessonUtils, { WithLessonUtilsProp } from '@hocs/WithLessonUtils'
import WithUserTimeHOC, { WithUserTimeProps } from '@hocs/WithUserTime'
import {
  DEFAULT_LANGUAGE,
  PROFILE_TYPE,
  VIRTUAL_CLASSROOM_PROVIDER,
  VIRTUAL_CLASSROOM_PROVIDERS,
} from '@utils/constants'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IOnlineClassroom, IPJoinLessonButton } from './interface'
import { JoinAnchor, JoinButton } from './style'
import ErrorContent from '@components/Modal/GenericErrorModal'
import PopupBlockerModal from '@components/Modal/PopupBlockerModal'
import RoleBasedView from '@components/RoleBasedView'
import PutVirtualClassroom from './PutVirtualClassroom'

const JoinLessonButton: React.FC<IPJoinLessonButton & WithUserTimeProps & WithModalProps & WithLessonUtilsProp> = (
  props
) => {
  const {
    getDateWithUserTimezone,
    getUserDate,
    lesson,
    profile,
    fullWidth,
    small,
    startTimeOverride,
    endTimeOverride,
    modal,
    title,
    useAnchor,
    studentId,
    onCompleted = () => {},
  } = props
  const isStudent = profile.Type === PROFILE_TYPE.STUDENT
  const [modalLoading, setModalLoading] = useState(false)
  const [blockerModal, setBlockerModal] = useState(false)
  const end = endTimeOverride ? endTimeOverride : lesson?.EndTime
  const endTime = getDateWithUserTimezone(end)
  const provider = (lesson?.VirtualClassRoomProvider ?? '').toLowerCase()
  const OnlineLessonURL =
    lesson?.OnlineLessonURL ||
    lesson?.SessionInfoList?.find(({ SessionId }) => SessionId === lesson?.SessionId)?.OnlineLessonURL ||
    lesson?.OnlineLessonURL ||
    new PortalPrograms(profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers).getProgram(
      lesson?.ProgramId
    )?.program?.OnlineLessonURL
  const start = startTimeOverride ? startTimeOverride : lesson?.StartTime
  const ROOM_LOCKED_MSG = 'Room Unavailable before start time'
  if (!provider || getUserDate().isAfter(endTime)) {
    return null
  }
  const userInfo = isStudent
    ? {
        role: 'student',
        email: profile?.StudentProfile?.StudentProfileInfo?.Email ?? '',
        givenName: profile?.StudentProfile?.StudentProfileInfo?.FirstName ?? '',
        familyName: profile?.StudentProfile?.StudentProfileInfo?.LastName ?? '',
        locale: profile?.StudentProfile?.StudentProfileInfo?.PortalLanguage ?? DEFAULT_LANGUAGE,
        custom_tp_playlist_id: new PortalPrograms(
          profile?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers
        ).getProgram(lesson?.ProgramId)?.program?.Materials?.[0]?.LPID,
      }
    : {
        role: 'instructor',
        email: profile?.InstructorProfile?.instructorInfo?.contactInfo?.Email ?? '',
        givenName: profile?.InstructorProfile?.instructorInfo?.contactInfo?.FirstName ?? '',
        familyName: profile?.InstructorProfile?.instructorInfo?.contactInfo?.LastName ?? '',
        locale: profile?.InstructorProfile?.instructorInfo?.PreferredLanguage ?? DEFAULT_LANGUAGE,
      }
  let roomDetails: IOnlineClassroom = {
    provider,
    userId: profile.SFId,
    ...userInfo,
    roomId: lesson?.ProgramId ?? '',
    roomTitle: lesson?.ProgramName ?? '',
    sessionId: lesson?.SessionId,
    startDate: start,
    endDate: end,
  }
  if (provider === VIRTUAL_CLASSROOM_PROVIDER.ZOOM) {
    roomDetails = {
      provider,
      userId: (isStudent ? profile.SFId : lesson.ZoomUsername) || '',
      ...userInfo,
      roomId: lesson?.VirtualClassRoomId ?? '',
      roomTitle: lesson?.ProgramName ?? '',
      sessionId: lesson?.SessionId,
      startDate: start,
      endDate: end,
    }
  }

  const SupportedProvider = VIRTUAL_CLASSROOM_PROVIDERS
  const JoinComponent = useAnchor ? JoinAnchor : JoinButton
  const notSupported = !SupportedProvider.some((i) => (lesson?.VirtualClassRoomProvider ?? '').toLowerCase() === i)

  const btnTitle = notSupported ? 'Not supported' : title || 'Join online'

  const errMsgLU = [
    {
      key: "'roomId' is a required field.",
      title: 'Oh no! Empty Room ID',
      description: 'Contact the Support team to fix up the Room ID for your course.',
    },
    {
      key: 'Meeting does not exist',
      title: 'Oh no! This room does not exist',
      description: 'Contact the Support team to check the Room or Classroom ID for your course.',
    },
    {
      key: "'userId' is a required field.",
      title: 'Oh no! Empty username',
      description: 'Contact the Support team to fix up your username in the system.',
    },
    {
      key: 'could not be found',
      title: 'Oh no! Invalid username',
      description: 'Contact the Support team to check your username has been created in the system.',
    },
  ]
  const defaultError = {
    title: 'Oh no! Something went wrong',
    description: 'Please try again later or contact the Support team.',
  }

  const openRoom = (roomURL) => {
    setModalLoading(false)
    modal.toggleModal(false)
    const popup = window.open(roomURL, '_blank')

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      setBlockerModal(true)
    }
  }

  const onOpenRoomError = (error: any) => {
    const errMsg = error?.message.split('GraphQL error:').filter(Boolean).pop()
    if (errMsg.trim() === ROOM_LOCKED_MSG) {
      setModalLoading(false)
      modal.toggleModal(
        true,
        <RoomLockedModal.Content onClose={() => modal.toggleModal(false)} role={userInfo?.role} />
      )
    } else {
      const errorObj = errMsgLU.find((i) => errMsg.trim().indexOf(i.key) >= 0) || defaultError
      setModalLoading(false)
      modal.toggleModal(
        true,
        // @ts-ignore
        <ErrorContent
          onClose={() => modal.toggleModal(false)}
          title={errorObj.title}
          description={errorObj.description}
        />
      )
    }
  }

  return (
    <>
      <JoinComponent
        fullWidth={fullWidth}
        small={small}
        disabled={notSupported}
        onClick={() => {
          setModalLoading(true)
          modal.toggleModal(true, <FetchingRoomModal.Content />)
        }}
        {...props}
      >
        <FormattedMessage id={btnTitle} />
      </JoinComponent>
      <>
        <RoleBasedView configId="c::modal::PopupBlocker">
          <PopupBlockerModal open={blockerModal} onClose={() => setBlockerModal(false)} />
        </RoleBasedView>
        {OnlineLessonURL && modalLoading && (
          <PutVirtualClassroom
            role={userInfo.role as any}
            userId={roomDetails.userId}
            sessionId={roomDetails.sessionId!}
            startDate={roomDetails.startDate!}
            endDate={roomDetails.endDate!}
            onCompleted={(data) => {
              if (data?.putSessionTimestamp.ServiceStatus?.StatusCode !== 200) {
                return onOpenRoomError({
                  message: data?.putSessionTimestamp.ServiceStatus?.Message || ROOM_LOCKED_MSG,
                })
              }

              openRoom(OnlineLessonURL)
            }}
            onError={(error) => onOpenRoomError(error)}
          />
        )}
        {!OnlineLessonURL && modalLoading && (
          <GetOnlineClassroomURL roomDetails={roomDetails} studentId={studentId} onCompleted={onCompleted}>
            {({ loading, data, error }) => {
              if (data?.getOnlineClassroom?.roomUrl && !loading) {
                openRoom(data?.getOnlineClassroom?.roomUrl)
              }

              if (error) {
                onOpenRoomError(error)
              }
              return <></>
            }}
          </GetOnlineClassroomURL>
        )}
      </>
    </>
  )
}

export default WithLessonUtils(WithUserTimeHOC(withModal(JoinLessonButton)))
