import { useMutation } from '@apollo/react-hooks'
import { PUT_SESSION_TIMESTAMP } from '@queries/virtualClassroom'
import { ApolloError } from 'apollo-client'
import React, { useEffect } from 'react'

interface IPutVirtualClassroom {
  role: 'student' | 'instructor'
  userId: string
  sessionId: string
  startDate: string
  endDate: string
  onCompleted: (data: IPutSessionTimestampMutation) => void
  onError: (error: ApolloError) => void
}

interface IPutSessionTimestampMutation {
  putSessionTimestamp: PutSessionTimestampRes
}

interface PutSessionTimestampRes {
  ServiceStatus: any
  ServiceResult: IPutVirtualClassroomRes[]
}

interface IPutVirtualClassroomRes {
  Id: string
  SessionTimestamp: string
  StudentId: string
}

const PutVirtualClassroom: React.FC<IPutVirtualClassroom> = ({
  role,
  userId,
  sessionId,
  startDate,
  endDate,
  onCompleted = () => {},
  onError = () => {},
}) => {
  const [putSessionTimestamp] = useMutation<IPutSessionTimestampMutation>(PUT_SESSION_TIMESTAMP, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError,
  })

  useEffect(() => {
    putSessionTimestamp({
      variables: {
        sessionTimestampInput: {
          role,
          userId,
          sessionId,
          startDate,
          endDate,
        },
      },
    })
  }, [])

  return <></>
}

export default PutVirtualClassroom
