export const nonLessons = [
  'Counselling',
  'Placement Test',
  'Soft Reservation',
  'Induction session',
  'Placements and Demo',
  'Cultural products',
  'Event',
  'Material preparation',
  'Professional development',
  'Meeting',
  'Administrative tasks',
  'Training Session',
  'Gap Filler',
  'Orientation Session',
]
