import { GroupClassListItem } from '@interfaces/MySchedule'
import { nonLessons } from '@utils/lesson-type'
import get from 'lodash/get'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import React from 'react'
import { Subtract } from 'utility-types'
import { ScheduleSession } from '@interfaces/ScheduleSession'
import { LessonHistory } from '@interfaces/LessonHistory'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import { UserProfileProps } from '@interfaces/UserProfile'
import { SCHEDULE_STATUS, PROFILE_TYPE, PROGRAM_TYPES } from '@utils/constants'

interface SessionMedata {
  StartTime: string
  EndTime: string
  Index: number
}
interface PatchFormMaterials {
  index: number
  field: string
  value: string
}

export interface WithLessonUtilsProp {
  /**
   * filter out lesson if status is null
   * or Rejected
   * @param lessons
   * @type GroupClassListItem[]
   * @returns GroupClassListItem[]
   */
  onlyValidLessons: (lessons: GroupClassListItem[]) => GroupClassListItem[]

  /**
   * filter out lesson if session is not equals
   * to passed Session Id
   * or Rejected
   * @param lessons
   * @type GroupClassListItem[]
   * @returns GroupClassListItem[]
   */
  onlyLessonsWithSameSession: (lessons: GroupClassListItem[], SessionId: string) => GroupClassListItem[]

  /**
   * check if there is unregistered lesson in a list
   * @param lessons
   * @type GroupClassListItem[]
   * @param datetimeToday - to be able to identify if lessons are in past
   * @type moment.Moment
   * @param toUserDate - taken from WithUserTime, convert raw time to user preferred time
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns false - if lessons are all registered, its not gap filler and all lessons are in past
   * otherwise true
   */
  hasUnregisteredLesson: (
    lessons: GroupClassListItem[],
    datetimeToday: moment.Moment,
    toUserDate: (date: string, format?: string, displayFormat?: string) => string
  ) => boolean

  /**
   * check if lesson program type belongs
   * to live online
   * @param lesson
   * @type GroupClassListItem
   * @returns true - if lesson program type belongs to the following list
   * - Private Online Level Based
   * - Private Online Skill Based
   * - Group(3) Online Level Based
   * - Group(3) Online Level Based
   * - Group(3) Online Skill Based
   * - Group(6) Online Level Based
   * - Group(6) Online Skill Based
   *
   * otherwise false.
   */
  isLiveOnline: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson learning center is live online virtual center
   * otherwise false.
   */
  isLiveOnlineVirtualCenter: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * to face to face
   * @param lesson
   * @type GroupClassListItem
   * @returns true - if lesson program type belongs to the following list
   * - Private Face to Face Level Based
   * - Private Face to Face Skill Based
   * - Group(3) Face to Face Level Based
   * - Group(3) Face to Face Skill Based
   * - Group(6) Face to Face Level Based
   * - Group(6) Face to Face Skill Based
   * - Group Large Face to Face Level Based
   * - Group Large Face to Face Skill Based
   *
   * otherwise false.
   */
  isFaceToFace: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * Live coaching session
   * @param lesson
   * @type GroupClassListItem
   * @returns true - if lesson program type belongs to the following list
   * - Live coaching session
   *
   * otherwise false.
   */
  isLiveCoachingSession: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * to face to face or live online or berlitz flex.
   * This function is a wrapper for `isLiveOnline` or `isFlex`.
   * @param lesson
   * @type GroupClassListItem
   * @returns true or false.
   */
  isProgramOnline: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * to face to face or live online or berlitz flex.
   * This function is a wrapper for `isLiveOnline`, `isFaceToFace` `isFlex`.
   * @param lesson
   * @type GroupClassListItem
   * @returns true or false.
   */
  isProgramLesson: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * to Berlitz Flex
   * @param lesson
   * @type GroupClassListItem
   * @returns true - if lesson program type belongs to the following list
   * - Berlitz Flex
   *
   * otherwise false.
   */
  isFlex: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson program type belongs
   * to Berlitz On demand + 1:1 Classes
   * @param lesson
   * @type GroupClassListItem
   * @returns true - if lesson program type belongs to the following list
   * - Berlitz Flex
   *
   * otherwise false.
   */
  isBoD1: (lesson: GroupClassListItem) => boolean

  /**
   * check if lesson is group
   * @param lesson
   * @type GroupClassListItem
   * @returns true
   * otherwise false.
   */
  isGroup: (lesson: GroupClassListItem) => boolean

  /**
   * format schedule session to lesson history
   * for pedagogical record displaying.
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getLessonHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * for pedagogical record displaying.
   * * PTL-1470: filter out Cancelled and Rescheduled
   * * PTL-1470: filter out languages not same as active program
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @param language
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getAllLessonHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string,
    language: string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * based on flex conditions
   * for pedagogical record displaying.
   * This function is a wrapper for `isLiveOnline` and `isFaceToFace`.
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns tLessonHistory[]
   */
  getFlexLessonHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * based on flex ProgramTypes
   * for pedagogical record displaying.
   * * PTL-1470: filter out Cancelled and Rescheduled
   * * PTL-1470: filter out languages not same as active program
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns tLessonHistory[]
   */
  getAllFlexLessonHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string,
    language: string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * based on live coaching session  conditions
   * for pedagogical record displaying.
   * This function is a wrapper for `isLiveOnline` and `isFaceToFace`.
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getLiveCoachingHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * based on live coaching session  conditions
   * for pedagogical record displaying.
   * * PTL-1470: filter out Cancelled and Rescheduled
   * * PTL-1470: filter out languages not same as active program
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getAllLiveCoachingHistory: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string,
    language: string
  ) => LessonHistory[]

  /**
   * format schedule session to lesson history
   * filtered it to the current insturctor
   * for pedagogical record displaying.
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getCurrentInstLessonHistory: (schedules: LessonHistory[], profile: UserProfileProps) => LessonHistory[]

  /**
   * returned Basic meta data for a session.
   *
   * If there are multiple Session with the same SessionId
   * this will be group and order by date.
   * StartTime will be pull from StartTime of the first Session and
   * EndTime will be pull from EndTime of the last Session.
   * @param lessons
   * @type GroupClassListItem[]
   * @param currentLesson
   * @type GroupClassListItem
   * @returns SessionMedata
   */
  getSessionMetadata: (lessons: GroupClassListItem[], currentLesson: GroupClassListItem) => SessionMedata

  /**
   * check Materials if MaterialName or ChapterName is empty
   * and do a lookup in LP units for these information
   * and apply these to Materials
   * @returns PathFormMaterials[]
   */
  patchFormMaterials: () => void

  /**
   * format schedule session to lesson history
   * based on berlitz on deman 1:1 Class conversation conditions
   * for pedagogical record displaying.
   * This function is a wrapper for `isLiveOnline` and `isFaceToFace`.
   * @param schedules
   * @type ScheduleSession[]
   * @param toUserDate
   * @type (date: string, format?: string, displayFormat?: string) => string
   * @returns LessonHistory[]
   */
  getAllBoD1History: (
    schedules: ScheduleSession[],
    toUserDate: (date: string, format?: string, displayFormat?: string) => string,
    language: string
  ) => LessonHistory[]
}

const nlt = nonLessons.map((lesson) => lesson.toLowerCase())

export default function WithLessonUtils<T extends WithLessonUtilsProp>(Component: React.ComponentType<T>) {
  class WithLessonUtilsHOC extends React.Component<Subtract<T, WithLessonUtilsProp>> {
    private toLessonHistory = (toUserDate: (date: string, format?: string, displayFormat?: string) => string) => {
      const mapper = ({
        StudentName,
        StartTime,
        IsGoalAchieved,
        Attendance,
        IsLastHomeworkDone,
        LateMinutes,
        Type,
        SessionId,
        ...rest
      }: ScheduleSession): LessonHistory => {
        const date = toUserDate(StartTime || '')
        const late = LateMinutes ? `(${LateMinutes} mins)` : ''
        const materials = filter(get(rest, 'Materials', []), (material) => material.Status !== 'Deleted')

        let attendance = ''
        if (Attendance && Attendance === 'Late') {
          attendance = `${Attendance} ${late}`
        } else {
          attendance = Attendance ? `${Attendance}` : ''
        }
        return {
          lpid: get(rest, 'LPId', ''),
          materials,
          date,
          StartTime,
          grading: get(rest, 'Grading', 0),
          type: Type,
          id: get(rest, 'Id', ''),
          description: get(rest, 'Description', ''),
          goalCompleted: IsGoalAchieved ? 'Completed' : 'Pending',
          goals: get(rest, 'GoalNotAchieved', []),
          speakingGoals: get(rest, 'SpeakingGoals', []),
          instructor: get(rest, 'InstructorName', ''),
          language: get(rest, 'Language', ''),
          attendance: attendance.trim(),
          isRegistered: get(rest, 'LessonRegistered', false),
          unitId: get(rest, 'UnitId', ''),
          unitFullname: get(rest, 'UnitFullName', ''),
          sessionId: SessionId,
          isLastHomeworkDone: IsLastHomeworkDone === undefined ? null : IsLastHomeworkDone,
          studentNote: get(rest, 'StudentNote', ''),
          programId: get(rest, 'ProgramId', ''),
          homework: {
            status: IsLastHomeworkDone ? 'Completed' : 'Pending',
            title: get(rest, 'Homework', ''),
            id: get(rest, 'Homework', ''),
          },
        }
      }
      return mapper
    }

    onlyValidLessons = (lessons: GroupClassListItem[]) => {
      const invalid = ['Rejected', 'Cancelled']
      return lessons.filter((lesson) => lesson.Status && !invalid.includes(lesson.Status))
    }

    onlyLessonsWithSameSession = (lessons: GroupClassListItem[], SessionId: string) =>
      lessons.filter((lesson) => lesson.SessionId === SessionId)

    hasUnregisteredLesson = (
      lessons: GroupClassListItem[],
      datetimeToday: moment.Moment,
      toUserDate: (date: string, format?: string, displayFormat?: string) => string
    ) => {
      const datetimeFormat = 'YYYY-MM-DD HH:mm:ss'
      const maxEndTime = moment.max(
        orderBy(lessons, ['EndTime']).map(({ EndTime }) =>
          moment(toUserDate(EndTime, datetimeFormat, datetimeFormat), datetimeFormat)
        )
      )

      return lessons.reduce((accumulator, lesson) => {
        // https://berlitz.atlassian.net/browse/PTL-753
        if (
          !lesson.LessonRegistered &&
          !includes(nlt, (lesson.Type || '').toLowerCase()) &&
          datetimeToday.isAfter(maxEndTime)
        ) {
          accumulator = true
        }

        if (
          !lesson.LessonRegistered &&
          !includes(nlt, (lesson.Type || '').toLowerCase()) &&
          datetimeToday.isBefore(maxEndTime)
        ) {
          accumulator = true
        }

        if (
          lesson.LessonRegistered &&
          !includes(nlt, (lesson.Type || '').toLowerCase()) &&
          datetimeToday.isBefore(maxEndTime)
        ) {
          accumulator = true
        }

        if (includes(nlt, (lesson.Type || '').toLowerCase()) && datetimeToday.isBefore(maxEndTime)) {
          accumulator = true
        }

        return accumulator
      }, false)
    }

    isLiveOnline = (lesson: GroupClassListItem) => (lesson.Type || '').includes('Online')

    isLiveOnlineVirtualCenter = (lesson: GroupClassListItem) =>
      (get(lesson, 'LearningCenter', '') || '').toLowerCase() === 'Live Online Virtual Center'.toLowerCase()

    isFaceToFace = (lesson: GroupClassListItem) => (lesson.Type || '').includes('Face to Face')

    isFlex = (lesson: GroupClassListItem) => (lesson.Type || '').includes('Flex')

    isBoD1 = (lesson: GroupClassListItem) => (lesson.Type || '').includes('Berlitz On Demand + 1:1 Classes')

    isGroup = (lesson: GroupClassListItem) => (lesson.ProgramType || '').toLocaleLowerCase().includes('group')

    isLiveCoachingSession = (lesson: GroupClassListItem) => (lesson.Type || '').includes('coaching')

    isProgramOnline = (lesson: GroupClassListItem) => this.isLiveOnline(lesson) || this.isFlex(lesson)

    isProgramLesson = (lesson: GroupClassListItem) =>
      this.isFaceToFace(lesson) || this.isLiveOnline(lesson) || this.isFlex(lesson)

    getLessonHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(({ LessonRegistered, SpeakingGoals }) => LessonRegistered && !SpeakingGoals.length)
        .map(this.toLessonHistory(toUserDate))

    getAllLessonHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string,
      language: string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(
          ({ Status, Type, Language }) =>
            [SCHEDULE_STATUS.CANCELLED, SCHEDULE_STATUS.RESCHEDULED].indexOf(Status) < 0 &&
            PROGRAM_TYPES.FLEX.indexOf(Type || '') < 0 &&
            [language].indexOf(Language || '') >= 0
        )
        .map(this.toLessonHistory(toUserDate))

    getFlexLessonHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(
          ({ LessonRegistered, SpeakingGoals, Materials }) =>
            LessonRegistered && SpeakingGoals.length && get(Materials, '0.Status') === 'Complete'
        )
        .map(this.toLessonHistory(toUserDate))

    getAllFlexLessonHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string,
      language: string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(
          ({ Status, Type, Language }) =>
            [SCHEDULE_STATUS.CANCELLED, SCHEDULE_STATUS.RESCHEDULED].indexOf(Status) < 0 &&
            PROGRAM_TYPES.FLEX.indexOf(Type || '') >= 0 &&
            [language].indexOf(Language || '') >= 0
        )
        .map(this.toLessonHistory(toUserDate))

    getAllBoD1History = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string,
      language: string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(
          ({ Status, Type, Language }) =>
            [SCHEDULE_STATUS.CANCELLED, SCHEDULE_STATUS.RESCHEDULED].indexOf(Status) < 0 &&
            PROGRAM_TYPES.ON_DEMAND_1.indexOf(Type || '') >= 0 &&
            [language].indexOf(Language || '') >= 0
        )
        .map(this.toLessonHistory(toUserDate))

    getLiveCoachingHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(({ LessonRegistered, SpeakingGoals }) => LessonRegistered && SpeakingGoals.length)
        .map(this.toLessonHistory(toUserDate))

    getAllLiveCoachingHistory = (
      schedules: ScheduleSession[],
      toUserDate: (date: string, format?: string, displayFormat?: string) => string,
      language: string
    ) =>
      orderBy(schedules, ['StartTime'], ['desc'])
        .filter(
          ({ Status, Type, Language }) =>
            [SCHEDULE_STATUS.CANCELLED, SCHEDULE_STATUS.RESCHEDULED].indexOf(Status) < 0 &&
            PROGRAM_TYPES.FLEX.indexOf(Type || '') >= 0 &&
            [language].indexOf(Language || '') >= 0
        )
        .map(this.toLessonHistory(toUserDate))

    getCurrentInstLessonHistory = (lessonHistory: LessonHistory[], profile: UserProfileProps) => {
      const name = [
        get(profile, 'InstructorProfile.instructorInfo.contactInfo.FirstName'),
        get(profile, 'InstructorProfile.instructorInfo.contactInfo.LastName'),
      ].join(' ')

      return lessonHistory.filter((lesson) => lesson.instructor === name)
    }

    getSessionMetadata = (lessons: GroupClassListItem[], currentLesson: GroupClassListItem) => {
      let instances = lessons.filter((lesson) => lesson.SessionId === currentLesson.SessionId)
      instances = sortBy(instances, (instance) => moment.utc(instance.StartTime, 'YYYY-MM-DD HH:mm:ss').toDate())

      const sessionMetadata: SessionMedata = {
        StartTime: instances?.[0]?.StartTime ?? '',
        EndTime: instances?.[instances.length - 1]?.EndTime ?? '',
        Index: findIndex(instances, { Id: currentLesson.Id }) + 1,
      }

      return sessionMetadata
    }

    patchFormMaterials = () => {
      // @ts-ignore
      const { formikProps, materialOptions, chapterOptions } = this.props
      const formMaterials = formikProps.values.Materials.reduce(
        (accumulator: PatchFormMaterials[], formMaterial, index) => {
          if (!formMaterial.MaterialName) {
            const m = materialOptions.find((material) => material.Id === formMaterial.Material)
            if (m) {
              accumulator.push({
                index: index,
                field: 'MaterialName',
                value: m.Name || '',
              })
            }
          }

          if (!formMaterial.ChapterName) {
            const c = chapterOptions.find((chapter) => chapter.Id === formMaterial.Chapter)
            if (c) {
              accumulator.push({
                index: index,
                field: 'ChapterName',
                value: c.Name,
              })
            }
          }

          return accumulator
        },
        [] as any
      )

      setTimeout(() => {
        formMaterials.forEach((material) => {
          formikProps.setFieldValue(`Materials.${material.index}.${material.field}`, material.value)
        })
      }, 0)
    }

    render() {
      const { ...rest } = this.props
      const enhanceProps = {
        ...rest,
        onlyValidLessons: this.onlyValidLessons,
        onlyLessonsWithSameSession: this.onlyLessonsWithSameSession,
        hasUnregisteredLesson: this.hasUnregisteredLesson,
        isLiveOnline: this.isLiveOnline,
        isLiveOnlineVirtualCenter: this.isLiveOnlineVirtualCenter,
        isFaceToFace: this.isFaceToFace,
        isLiveCoachingSession: this.isLiveCoachingSession,
        isProgramOnline: this.isProgramOnline,
        isProgramLesson: this.isProgramLesson,
        isFlex: this.isFlex,
        isBoD1: this.isBoD1,
        isGroup: this.isGroup,
        getLessonHistory: this.getLessonHistory,
        getFlexLessonHistory: this.getFlexLessonHistory,
        getSessionMetadata: this.getSessionMetadata,
        patchFormMaterials: this.patchFormMaterials,
        getCurrentInstLessonHistory: this.getCurrentInstLessonHistory,
        getAllFlexLessonHistory: this.getAllFlexLessonHistory,
        getAllLessonHistory: this.getAllLessonHistory,
        getAllLiveCoachingHistory: this.getAllLiveCoachingHistory,
        getAllBoD1History: this.getAllBoD1History,
      }

      return <Component {...(enhanceProps as T)} />
    }
  }

  return WithLessonUtilsHOC
}
